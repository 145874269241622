/*
	toner-graph styles
*/

@import "variables/variables";

//Variables
$max-width: 400px;
$height: 240px;
$cartridge-width: 20%;

.toner-graph {
  font-size: 12px;
  width: 100%;
  max-width: $max-width;
  height: $height;
  position: relative;
  margin: 10px;
  box-sizing: border-box;

  .scale {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: space-between;

    .scale-value {
      width: 100%;
      //padding-bottom: 10%;

      .scale-label {
        display: block;
        font-size: 11px;
        position: absolute;
        transform: translate(-50%, -50%);
      }
      .line {
        display: block;
        width: 96%;
        margin-left: 4%;
        height: 1px;
        background-color: #ccc;
        transform: translateY(-50%);
      }
    }
  }
  .cartridges-wrapper {
    position: absolute;
    top: 0px;
    z-index: 10;
    height: 100%;
    width: 100%;
    padding: 0 5%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: flex-end;
    align-content: space-between;

    .toner-level-alert-percentage-bottom-placeholder {
      background-color: rgba(255, 255, 255, 0.4);
      position: absolute;
      bottom: 0;
      height: 5%;
      width: 100%;
    }

    .cartridge {
      height: 100%;
      width: $cartridge-width;
      min-width: 40px;
      position: relative;

      .toner-level {
        position: absolute;
        bottom: 0;
        width: 100%;

        .toner-level-value {
          font-weight: bold;
          text-align: center;
          transform: translateY(-120%);
        }
      }
      .toner-level.shortened {
        border-top: 2px dashed #ff0000;
        -moz-background-clip: content;     /* Firefox 3.6 */
        -webkit-background-clip: content;  /* Safari 4? Chrome 6? */
        background-clip: content-box;      /* Firefox 4, Safari 5, Opera 10, IE 9 */
      }
      label {
        display: block;
        padding: 9px 0;
        text-align: center;
        position: absolute;
        top: 97%;
        width: 100%;

        .toner-level-color-label{
          min-height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .toner-level-text-val {
        font-size: 10px;
        width: 26px;
        height: 18px;
      }

      .toner-level-alert {
        height: 100%;
      }

      .toner-level-alert-percentage {
        position: absolute;
        min-height: 5%;
        height: 55%;
        max-height: 55%;
        bottom: 5%;
      }

      .toner-level-alert,
      .toner-level-alert-percentage {
        background: none;
        border: none;
        width: 100%;

        .ui-slider-range {
          background-color: rgba(255, 255, 255, 0.4);
        }
        .ui-slider-handle {
          background-color: rgba(0, 0, 0, 0.6);
          border: none;
          border-radius: 0;
          height: 2px;
          width: 100%;
          cursor: n-resize;
          padding: 0;
          margin: 0;
          transform: translate(0, 50%);
          box-shadow: none;
          left: 0;
        }
      }
    }
    .toner-color-c {
      .toner-level { background-color: rgba(0, 147, 211, 0.7); }
    }
    .toner-color-m {
      .toner-level { background-color: rgba(204, 0, 107, 0.7); }
    }
    .toner-color-y {
      .toner-level { background-color: rgba(255, 241, 12, 0.7); }
    }
    .toner-color-k {
      .toner-level { background-color: rgba(0, 0, 0, 0.7); }
    }
    .toner-color-x {
      .toner-level { background-color: rgba(150, 150, 150, 0.7); }
    }
  }
  .toners-level-all-alert-wrapper,
  .toners-level-all-alert-wrapper-percentage {
    .toners-level-all-alert {
      width: 105%;
      height: 100%;
      background: none;
      border: none;

      .ui-slider-range {
        background-color: transparent;
        border-top: 1px dotted #ff0000;
      }
      .ui-slider-handle {
        background-color: transparent;
        border: none;
        border-radius: 0;
        height: 0px;
        width: 0px;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: 10px solid #ff0000;
        cursor: n-resize;
        padding: 0;
        margin: 0;
        transform: translate(0, 50%);
        box-shadow: none;
        left: 100%;
      }
    }
  }

  .toners-level-all-alert-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 5;
  }

  .toners-level-all-alert-wrapper-percentage {
    position: absolute;
    width: 100%;
    min-height: 5%;
    height: 55%;
    max-height: 55%;
    bottom: 5%;
    z-index: 5;
  }
}

.new_slider-wrapp{
  width: 100%;
  max-width: 400px;
  min-height: 240px;
  height: 240px;
  position: relative;
  .scales{
    width: calc(100% - 30px);
    margin-left: 30px;
    height: 100%;
    .scale{
      width: 8%;
      height: 100%;
      font-size: 11px;
      display: inline-block;
      .line{
        border-left: 1px dashed #ccc;
        width: 1px;
        height: 100%;
      }
    }
  }
  .graph-container{
    position: absolute;
    width: calc(100% - 65px);
    height: 100%;
    top: 30px;
    left: 30px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    span{
      position: absolute;
      left: -45px;
      font-size: 10px;
      font-weight: 700;
      top: 50%;
      margin-top: -10px;
      .color-percent{
        width: 26px;
        height: 20px;
        font-size: 11px;
        margin-right: 2px
      }
    }
    .solid-color{
      width: 100%;
      height: 20px;
      display: block;
      position: relative;
      margin: 3px 0;
      .color{
        width: 100%;
        height: 100%;
        &.magenta{
          background: rgba(204,0,107,.7);
        }
        &.cyan{
          background: rgba(0,147,211,.7);
        }
        &.grey{
          background: rgba(150,150,150,.7);
        }
        &.yellow{
          background: rgba(255,241,12,.7);
        }
        &.black{
          background: rgba(0,0,0,.7);
        }
      }
      .alert-level{
        position: absolute;
        top: 0px;
        height: 100%;
        width: 40%;
        background: rgba(255, 255, 255, .4);
        span{
          position: absolute;
          top: 0;
          left: 100%;
          width: 2px;
          height: 100%;
          background: #000;
          cursor: e-resize;
          margin-top: 0px;
        }
      }
      label{
        position: absolute;
        top: 50%;
        left: 3px;
        font-size: 11px;
        margin-top: -7px;
      }
    }
  }
  .limiter{
    position: absolute;
    width: 40%;
    height: 100%;
    top: 30px;
    border-right: 1px dotted red;
    .triangle-up{
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid red;
      position: absolute;
      right: 0;
      bottom: -5px;
      margin-right: -5px;
      cursor: e-resize;
      .square-up{
        width: 9.4px;
        height: 5px;
        background: red;
        display: inline-block;
        position: absolute;
        top: 5px;
        left: -4.7px;
      }
    }
    .triangle-down{
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #f00;
      position: absolute;
      right: 0;
      top: -5px;
      margin-right: -5px;
      cursor: e-resize;
      .square-down{
        width: 9.4px;
        height: 5px;
        background: red;
        display: inline-block;
        position: absolute;
        top: -10px;
        left: -4.7px;
      }
    }
  }
}

/* NEW CHART */

.charts {
  position: relative;

  .chart-wrapper{
    &.preview {
      position: absolute;
      top: -25px;
      left: -22px;
      padding: 50px 30px 30px 70px;
      background: #fff;
      border-radius: 2px;
      box-shadow: 0 2px 5px 2px rgba(0, 0, 0, .1);
      border: 2px solid #505050;
      z-index: 100;
      transform-origin: 50% 50%;
      animation: 300ms preview-in forwards;
      transition: 300ms;
      width: 520px;

      &:before {
        content: ' ';
        display: block;
        position: absolute;
        left: -10px;
        top: -10px;
        border-radius: 50%;
        z-index: 101;
        background: url(#{$base_url}/images/icons/eye.svg) no-repeat #ffffff center;
        background-size: 20px;
        width: 30px;
        height: 30px;
        border: 2px solid #505050;
      }

      @keyframes preview-in {
        0% {
          opacity: 0;
          transform: scale(.5);
        }
        100% {
          opacity: 1;
          transform: scale(1);
        }
      }


      & .chart{
        margin: 0;
      }
    }

    &.chart-empty .chart{
      margin: 40px 5px;
    }

    .chart {
      width: 100%;
      max-width: 400px;
      height: 280px;
      margin: 40px 30px;
      position: relative;
      @media (max-width: 800px) {
        max-width: unset;
        width: calc(100% - 100px);
        margin: 40px 50px;
      }

      .scales {
        height: 100%;
        display: flex;
        display: -ms-flex;
        justify-content: space-between;

        .scale {
          height: 100%;
          position: relative;
          border-right: 1px solid #eeeeee;

          label {
            text-align: center;
            position: absolute;
            left: 0;
            top: -25px;
            transform: translate(-50%, 0);
            font-size: 12px;
            font-weight: 700;
            max-width: unset;
          }
        }
      }

      .bars {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .bar {
          height: 25px;
          position: relative;

          &.large{
            height: 50px;

            .supply-bar{
              label{
                top: 50%;
                margin-top: -8px;
              }
            }
          }

          .supply-input {
            position: absolute;
            left: -50px;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;

            input {
              width: 28px;
              height: 20px;
              font-size: 11px;
              margin-right: 3px;
            }

            label {
              margin-bottom: unset;
              font-size: 12px;
              font-weight: 700;
            }
          }

          .supply-bar {
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: cadetblue;
            display: flex;
            align-items: center;
            padding-left: 10px;

            &.max-days{
              border-right: 2px dashed red;
            }

            label {
              font-size: 12px;
              font-weight: 700;
              margin-bottom: unset;
              color: black;
              position: absolute;
              top: 50%;
              margin-top: -8px;
              max-width: unset;
              min-width: 150px;
              width: max-content;
              background: transparent;
              color: #000000;
                text-shadow:
               -1px -1px 0 rgba(255,255,255, .5),
                1px -1px 0 rgba(255,255,255, .5),
                -1px 1px 0 rgba(255,255,255, .5),
                 1px 1px 0 rgba(255,255,255, .5);
            }

            &.supply-m {
              background: rgba(204, 0, 107, .7);
            }

            &.supply-c {
              background: rgba(0, 147, 211, .7);
            }

            &.supply-x {
              background: rgba(150, 150, 150, .7);
            }

            &.supply-y {
              background: rgba(255, 241, 12, .7);
            }

            &.supply-k {
              background: rgba(0, 0, 0, .7);
            }
          }

          .threshold-bar {
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(255, 255, 255, .3);
            resize: horizontal;
          }

          .threshold-slider {
            position: absolute;
            top: 0;
            height: 100%;
            width: 2px;
            background-color: black;
            cursor: e-resize;
            z-index: 99;

            &:active {
              width: 3px;
            }
          }
        }

        .threshold-main-slider {
          position: absolute;
          left: 20%;
          top: 0;
          height: 100%;
          border-right: 1px dashed red;

          &:before {
            content: '';
            width: 0;
            height: 0;
            position: absolute;
            bottom: -6px;
            left: -4.5px;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 8px solid red;
            cursor: e-resize;
          }

          &:after {
            content: '';
            width: 10px;
            height: 7px;
            background-color: red;
            bottom: -13px;
            left: -4.5px;
            position: absolute;
            cursor: e-resize;
          }
        }
      }
    }
  }
}